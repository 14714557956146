import { useEffect, useMemo, useState } from 'react';
import { LinkIcon, HomeIcon, UsersIcon, CircleStackIcon } from '@heroicons/react/24/outline';
import { usePathname } from 'next/navigation';
import { useSession } from '@clerk/nextjs';
import { useClerkFeatureFlags } from './useClerkFeatureFlags';
import { useOrgType } from './useOrgType';

type navigationItem = {
  name: string;
  href: string;
  icon: any;
  current: boolean;
};

const useNavigation = () => {
  const [currentNavItem, setCurrentNavItem] = useState<string>('');
  const { featureFlagsData: clerkFeatureFlagsData } = useClerkFeatureFlags();
  const {
    orgType: { isPublisher, isAdvertiser },
  } = useOrgType();

  const { isLoaded } = useSession();
  const pathname = usePathname();

  useEffect(() => {
    if (pathname) {
      setCurrentNavItem(pathname);
    }
  }, [currentNavItem, isLoaded, pathname]);

  const isNotPublisher = !isPublisher;

  const navigation = useMemo(() => {
    return [
      { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: currentNavItem.startsWith('/dashboard/') },
      isNotPublisher ? { name: 'Links', href: '/links', icon: LinkIcon, current: currentNavItem === '/links' } : undefined,
      clerkFeatureFlagsData?.referrals
        ? { name: 'Campaigns', href: '/campaigns', icon: UsersIcon, current: currentNavItem.startsWith('/campaigns') }
        : undefined,
      {
        name: 'Audiences',
        href: '/audiences',
        icon: UsersIcon,
        current: currentNavItem.startsWith('/audiences'),
      },
      isPublisher ? { name: 'Placements', href: '/placements', icon: UsersIcon, current: currentNavItem.startsWith('/placements') } : undefined,
      isNotPublisher ? { name: 'Data', href: '/data', icon: CircleStackIcon, current: currentNavItem.startsWith('/data') } : undefined,
    ].filter((n) => n) as navigationItem[];
  }, [currentNavItem, clerkFeatureFlagsData, isPublisher, isAdvertiser]);

  return { navigation };
};

export default useNavigation;
