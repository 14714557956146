import { useAuth, useOrganization } from '@clerk/nextjs';
import { useQuery } from '@tanstack/react-query';
import { OrgType } from '../types';
import { axiosGet, UrlConfig } from '../utils/helpers';

export const useOrgType = () => {
  const { getToken } = useAuth();
  const { organization } = useOrganization();

  const {
    data: orgType,
    isLoading: isOrgTypeLoading,
    isError: isOrgTypeError,
  } = useQuery({
    queryKey: ['org-type'],
    queryFn: async () => {
      const token = await getToken();

      if (!token) throw new Error('No token found');
      // Only run the query if the active organization is available
      // and the user's session is available
      const url = `${UrlConfig.serverHost}/${organization?.publicMetadata.spindl_org_id}/config/org-type`;
      const response = await axiosGet(url, token);

      return response.data as OrgType;
    },
    // Enable the query if the active organization and user's session is available
    enabled: !!organization,
    // Disable refetching the query on window focus
    refetchOnWindowFocus: false,
  });

  const orgTypeDefault = orgType || { isPublisher: false, isAdvertiser: false };

  return { orgType: orgTypeDefault, isOrgTypeLoading, isOrgTypeError };
};
